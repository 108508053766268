/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-did-update-set-state */
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { cartItemsActions, modalActions } from 'actions';
import Spinner from 'components/Core/Spinner/Spinner';
import {
  allowedToOrder,
  getCartItem,
  getDiscountedPrice,
  getItemCartQuantity,
  getMaxQuantityOfProduct,
  getRemainingQuantity,
  getValueOfAttribut,
} from 'helpers';
import { envConstants } from 'constants/Env.constants';
import { analytics } from 'utils/analytics/tagmanager';
import QuantityHandler from 'components/Core/QuantityHandler/QuantityHandler';
import { ButtonPrimary } from 'components/Core/Button/Button';

const ProductQuantityTD = styled.td`
  white-space: nowrap;
`;

const ProductQuantityDIV = styled.div`
  // Override the width specified in the cell class name
  width: unset !important;
  height: 45px;

  display: flex;
  flex-direction: column;
  justify-content: center;
`;

class ProductQuantity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quantity: null,
      hasInitialized: false,
      commitmentRequestSent: false,
    };
    this.handleMinus = this.handleMinus.bind(this);
    this.handlePlus = this.handlePlus.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.getEffectiveCart = this.getEffectiveCart.bind(this);
    this.isCartInitialized = this.isCartInitialized.bind(this);
    this.hasCommitted = this.hasCommitted.bind(this);
    this.timer = false;
    this.timerToAdd = false;
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;

    const { product } = this.props;

    this.setState({
      quantity: getItemCartQuantity(product.id, this.getEffectiveCart()) || 0,
      hasInitialized: this.isCartInitialized(),
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { quantity, hasInitialized } = this.state;
    const { quantity: nextQuantity } = nextState;
    if (nextQuantity !== quantity || !hasInitialized) {
      return true;
    }
    if (this.getStoreQuantity(nextProps) !== quantity) {
      return true;
    }

    return false;
  }

  componentDidUpdate(prevProps) {
    /*
      setState is not synchronous
      so its very important that this part of logic remains in componentDidUpdate or at least
      we must make sure that what happens below is executed after the state change happening
      (eg: in the callback argument of setState)
    */
    const { hasInitialized, quantity } = this.state;

    const cartIsInitialized = this.isCartInitialized();
    const prevCartIsInitialized = this.isCartInitialized(prevProps);
    const storeQuantity = this.getStoreQuantity();

    if (
      (cartIsInitialized && !prevCartIsInitialized && !hasInitialized) ||
      quantity ||
      storeQuantity
    ) {
      this.setState({
        quantity: this.getStoreQuantity(),
        hasInitialized: true,
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getEffectiveCart(effectiveProps = null) {
    const { currentCart, currentPreorderCart, product } = effectiveProps || this.props;

    return product && product.is_preorder ? currentPreorderCart : currentCart;
  }

  getStoreQuantity(effectiveProps = null) {
    const { product } = effectiveProps || this.props;

    const effectiveCart = this.getEffectiveCart();
    const { cart_items: effectiveCartItems } = effectiveCart;
    const cartItem = effectiveCartItems
      ? effectiveCartItems.find(({ item_id: itemId }) => Number(itemId) === Number(product.id))
      : null;
    const { quantity: storeQuantity } = cartItem || 0;
    return storeQuantity;
  }

  getMaxQuantityOfProduct() {
    const { selectedPlatform, product } = this.props;
    return getMaxQuantityOfProduct(product, selectedPlatform);
  }

  getRemainingQuantity(quantity) {
    const { selectedPlatform, product } = this.props;
    return getRemainingQuantity(product, quantity, selectedPlatform);
  }

  canAddProducts = (quantityToAdd = 1) => {
    const { selectedPlatform, product } = this.props;
    const { quantity } = this.state;

    const effectiveCart = this.getEffectiveCart();
    return allowedToOrder(
      product,
      Number(quantityToAdd) + Number(quantity),
      effectiveCart,
      selectedPlatform
    );
  };

  addToCart(quantity) {
    const { hasInitialized, quantity: oldQuantity } = this.state;

    const { product, addToCart, saveItem } = this.props;

    const cartIsInitialized = this.isCartInitialized();

    if (quantity !== oldQuantity && cartIsInitialized && hasInitialized) {
      this.setState({
        quantity,
      });

      const productItem = product.hasOwnProperty('item') ? product.item : product; // eslint-disable-line
      const cartItem = getCartItem(productItem.id);
      const cartItemWithUpdatedQuantity = {...cartItem, ...{quantity}};
      // Force applicable price calculation before dispatch to store
      product.applicable_price = getDiscountedPrice(product, cartItemWithUpdatedQuantity);

      addToCart(
        product.is_preorder ? 'current_preorder' : 'current',
        product,
        Number(quantity) || 0,
        {}
      );

      if (this.timer) {
        clearTimeout(this.timer);
      }

      this.timer = setTimeout(() => {
        saveItem(product.is_preorder ? 'current_preorder' : 'current', product);
      }, 1000);
    }
  }

  isCartInitialized(effectiveProps = null) {
    const { product, currentPreorderIsInitialized, currentIsInitialized } =
      effectiveProps || this.props;

    if (product) {
      return product.is_preorder ? currentPreorderIsInitialized : currentIsInitialized;
    }

    return false;
  }

  handleMinus() {
    const { quantity } = this.state;
    const newQuantity = Math.max(Number(quantity) - 1, 0);
    this.addToCart(newQuantity);
  }

  handlePlus() {
    const { quantity } = this.state;
    if (this.canAddProducts()) {
      this.addToCart(Number(quantity) + 1);
    }
  }

  handleChange(e) {
    const nodeEnv = process.env.NODE_ENV;
    if (nodeEnv === envConstants.ENV_DEVELOPPEMENT) {
      console.log('[debounce] handleChange quantity', e);
    }
    const quantity = String(Number(e));
    if (quantity && quantity >= 0 && quantity <= 9999) {
      const maxQuantity = this.getMaxQuantityOfProduct();
      const checkedQuantity = !this.canAddProducts(Number(quantity))
        ? Math.max(maxQuantity, 0)
        : Number(quantity);

      if (Number(quantity) > checkedQuantity) {
        this.addToCart(checkedQuantity);
      } else {
        this.addToCart(Number(quantity));
      }
    }
  }

  handleBlur() {
    if (!this.canAddProducts(0)) {
      const maxQuantity = this.getMaxQuantityOfProduct();
      this.addToCart(Math.max(maxQuantity, 0));
    }
  }

  hasCommitted() {
    this.setState({
      commitmentRequestSent: true,
    });
  }

  render() {
    const {
      className,
      shouldShowResponsive,
      product,
      selectedPlatform,
      openModalEngagementStock,
      openModalErrorCart,
      isLoading,
      openSubtitutModal,
    } = this.props;
    const { quantity, commitmentRequestSent } = this.state;
    const { attributs } = selectedPlatform;
    const { promotion } = product;
    const platformStockDisplayOut = !!Number(
      getValueOfAttribut(attributs || [], 'stock-display-out')
    );

    return !shouldShowResponsive ? (
      <ProductQuantityTD className={className}>
        {isLoading ? (
          <Spinner />
        ) : (
          <React.Fragment>
            <div>
              <QuantityHandler
                quantity={quantity}
                handleMinus={this.handleMinus}
                handlePlus={this.handlePlus}
                handleChange={this.handleChange}
                handleBlur={this.handleBlur}
                disablePlus={!this.canAddProducts()}
              />
            </div>
            {platformStockDisplayOut && !this.canAddProducts() && (
              <span className="no-stock-left" title="Stock insuffisant">
                <i className="icon-attention" />
              </span>
            )}
            {promotion && promotion.promo_code === 'EF' && !this.canAddProducts() && (
              <span
                className="plus-stock"
                onClick={(e) => {
                  e.preventDefault();
                  if (promotion && !promotion.has_requested_commitment && !commitmentRequestSent) {
                    openModalEngagementStock(product, this.hasCommitted);
                  } else {
                    openModalErrorCart();
                  }
                }}
              >
                + de stock
              </span>
            )}
          </React.Fragment>
        )}
      </ProductQuantityTD>
    ) : (
      <ProductQuantityDIV className={className}>
        <QuantityHandler
          quantity={quantity}
          handleMinus={this.handleMinus}
          handlePlus={this.handlePlus}
          handleChange={this.handleChange}
          handleBlur={this.handleBlur}
          disablePlus={!this.canAddProducts()}
        />
        {platformStockDisplayOut && !this.canAddProducts() && (
          <span className="no-stock-left" title="Stock insuffisant">
            <i className="icon-attention" />
          </span>
        )}
        {promotion && promotion.promo_code === 'EF' && !this.canAddProducts() && (
          <span
            className="plus-stock"
            onClick={(e) => {
              e.preventDefault();
              if (promotion && !promotion.has_requested_commitment && !commitmentRequestSent) {
                openModalEngagementStock(product, this.hasCommitted);
              } else {
                openModalErrorCart();
              }
            }}
          >
            + de stock
          </span>
        )}
      </ProductQuantityDIV>
    );
  }
}

QuantityHandler.propTypes = {
  quantity: PropTypes.number,
  handleMinus: PropTypes.func,
  handlePlus: PropTypes.func,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  disabled: PropTypes.bool,
};

ProductQuantity.propTypes = {
  className: PropTypes.string,
  shouldShowResponsive: PropTypes.bool,
  product: PropTypes.object,
  addToCart: PropTypes.func,
  saveItem: PropTypes.func,
  selectedPlatform: PropTypes.object,
  openModalEngagementStock: PropTypes.func,
  openModalErrorCart: PropTypes.func,
  isLoading: PropTypes.bool,
};

function mapStateToProps(state) {
  const {
    cart,
    cartItems,
    platform: { selectedId, platforms },
  } = state;
  const { current, currentPreorder, currentIsInitialized, currentPreorderIsInitialized } = cart;
  const { isLoading } = cartItems;
  return {
    selectedPlatform: platforms.find((p) => Number(p.id) === Number(selectedId)) || {},
    cartItems,
    currentCart: current,
    currentPreorderCart: currentPreorder,
    currentIsInitialized,
    currentPreorderIsInitialized,
    isLoading,
  };
}

const mapDispatchToProps = (dispatch) => ({
  addToCart: (cartId, product, quantity, filter) => {
    if (quantity === 0) {
      analytics.removeFromCart(product);
    }
    dispatch(cartItemsActions.addItem(cartId, product, quantity, filter));
  },
  saveItem: (cartId, product) => {
    dispatch(cartItemsActions.saveItem(cartId, product));
  },
  openNewContainersModal: (product) =>
    dispatch(modalActions.open('newContainers', { width: '280', product })),
  openModalEngagementStock: (product, hasCommitted) =>
    dispatch(modalActions.open('engagementStock', { width: '500', product, hasCommitted })),
  openModalErrorCart: () =>
    dispatch(
      modalActions.open('errorCart', {
        width: '500',
        text: 'Vous avez déja fait une demande de stock.',
      })
    ),
  openSubtitutModal: (product) =>
    dispatch(
      modalActions.open('substitut', {
        item: product,
        width: '800',
        title: 'Produit de substitution',
      })
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductQuantity);
