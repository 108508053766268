import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import 'ag-grid-enterprise';
import moment from 'moment';
import { analytics } from 'utils/analytics/tagmanager';
import { productCardActions } from 'actions/ProductCard';
import CatalogControls from 'components/CatalogControlsV3/CatalogControls';
import { aggridFilterActions } from 'actions/AggridFilter';
import Spinner from 'components/Core/Spinner/Spinner';
import ProductCard from 'components/ProductCard';
import {
  selectItemsFromCatalog,
  selectCurrentProduct,
  selectTopSales,
  selectIsLoadingFromCatalog,
  selectNewsPromotionsFavoritesCountersProducts,
} from 'selectors/product';
import { selectCurrentPlatform, selectCurrentPlatformId } from 'selectors/platform';
import { aggridConstants } from 'constants/Aggrid.constants';
import { selectCategories } from 'selectors/category';
import { selectAggridSearchValue } from 'selectors/search';
import { productActions } from 'actions/Product';
import { selectAggridFilter, selectAggridViewType } from 'selectors/aggridFilter';
import { selectMessageList } from 'selectors/message';
import { toastActions } from 'actions/Toast';
import { catalogActions } from 'actions';
import { doFiltersPass } from 'helpers/Filter';
import { getValueOfAttribut, store } from 'helpers';
import map from 'lodash.map';
import { selectCurrentPageContext, selectCatalogCollectionCountByFilter } from 'selectors/catalog';
import { selectCurrentUser } from 'selectors/user';
import { toastConstants } from 'constants/Toast.constants';
import { catalogService } from 'services';
import { messageConstants, platformConstants, aggridFilterConstants } from '../../constants';
import {
  availabilityCellRenderer,
  priceCellRenderer,
  packagePriceCellRenderer,
  imageCellRenderer,
} from './cellRenderers';
import AssortmentFilter from './AssortmentFilter';
import AggridTableView from './TableView/AggridTableView';
import AggridListView from './ListView/AggridListView';

class Aggrid extends Component {
  constructor(props) {
    super(props);
    this.onFirstDataRendered = this.onFirstDataRendered.bind(this);
    this.onSelectionChanged = this.onSelectionChanged.bind(this);
    this.counter = 0;
    this.state = {
      totalRowCount: 0,
      rowCount: 0,
      counters: { new: 0, promotions: 0, favorites: 0 },
      filterType: aggridConstants.FILTER_NO_FILTER,
      hasResults: false,
    };
  }

  componentDidMount() {
    const { messageList, pushToast, fetchProductsTotalCount, platformId, user } = this.props;

    const currentMessageList = messageList
      .flat()
      .filter((item) => moment(item.date_end) >= moment());

    currentMessageList.map((item) => {
      if (item.type === messageConstants.TYPE_LOCKED) {
        if (user && user.client.locked) {
          return pushToast({
            type: 'warning',
            title: item.subject,
            message: item.content,
            duration: item.duration,
          });
        }

        return null;
      }

      return pushToast({
        type: toastConstants.TYPE_INFO,
        title: item.subject,
        message: item.content,
        duration: item.duration,
      });
    });
  }

  componentDidUpdate(prevProps) {
    if (this.gridColumnApi && prevProps.categoryDisplay !== this.props.categoryDisplay) {
      this.gridColumnApi.setColumnVisible('department', this.props.categoryDisplay);
      this.gridColumnApi.setColumnVisible('zone', this.props.categoryDisplay);
      this.gridColumnApi.setColumnVisible('aisle', this.props.categoryDisplay);
      this.gridColumnApi.setColumnVisible('category', this.props.categoryDisplay);
      this.gridColumnApi.setColumnVisible('sub_category', this.props.categoryDisplay);
    }
  }

  getFilterModel = (aggridFilter, filterModelSource) => {
    let filterModel = filterModelSource;
    if (aggridFilter && (aggridFilter.isPreorder === true || aggridFilter.isPreorder === false)) {
      if (aggridFilter.isPreorder !== null) {
        let isPreorderValue;
        if (aggridFilter.isPreorder === true) {
          isPreorderValue = 1;
        } else {
          isPreorderValue = 0;
        }
        filterModel = {
          ...filterModel,
          ...{
            preorder: {
              filterType: 'boolean',
              type: 'equals',
              filter: isPreorderValue,
            },
          },
        };
      }
    }
    if (aggridFilter && aggridFilter.categoryId) {
      if (Array.isArray(aggridFilter.categoryId)) {
        const baseArr = [];
        const fn = (obj) => {
          map(obj, (el, index) => {
            baseArr.push(el.id);
            if (el.children && Object.keys(el.children).length > 0) {
              fn(el.children);
            }
          });
        };
        fn(aggridFilter.categoryId);

        filterModel = {
          ...filterModel,
          ...{
            category: {
              filterType: 'array',
              type: 'equals',
              filter: [...new Set(baseArr)],
            },
          },
        };
      } else {
        filterModel = {
          ...filterModel,
          ...{
            category: {
              filterType: 'integer',
              type: 'equals',
              filter: aggridFilter.categoryId,
            },
          },
        };
      }
    }

    if (aggridFilter && aggridFilter.quickList) {
      const quickListArray = aggridFilter.quickList;
      let responseNew = 0;
      let responsePromotion = 0;
      let responseFavorite = 0;
      let responseTopSales = 0;

      if (quickListArray.includes(aggridFilterConstants.FILTER_TOP_SALES)) {
        responseTopSales = 1;
        filterModel = {
          ...filterModel,
          ...{
            topSales: {
              filterType: 'boolean',
              type: 'equals',
              filter: responseTopSales,
            },
          },
        };
      }

      // NEW
      if (quickListArray.includes(aggridFilterConstants.FILTER_NEW)) {
        responseNew = 1;
        filterModel = {
          ...filterModel,
          ...{
            new: {
              filterType: 'boolean',
              type: 'equals',
              filter: responseNew,
            },
          },
        };
      }

      // PROMOTION
      if (quickListArray.includes(aggridFilterConstants.FILTER_PROMOTION)) {
        responsePromotion = 1;
        filterModel = {
          ...filterModel,
          ...{
            promotion: {
              filterType: 'boolean',
              type: 'equals',
              filter: responsePromotion,
            },
          },
        };
      }
      // FAVORITE
      if (quickListArray.includes(aggridFilterConstants.FILTER_FAVORITE)) {
        responseFavorite = 1;
        filterModel = {
          ...filterModel,
          ...{
            favorite: {
              filterType: 'boolean',
              type: 'equals',
              filter: responseFavorite,
            },
          },
        };
      }
    }
    if (aggridFilter && aggridFilter.availabilityList && aggridFilter.availabilityList.length > 0) {
      const availabilityListArray = aggridFilter.availabilityList;

      if (
        availabilityListArray.length === 1 &&
        availabilityListArray.includes(aggridFilterConstants.FILTER_AVAILABLE)
      ) {
        // # STOCK COCHE UNIQUEMENT
        filterModel = {
          ...filterModel,
          ...{
            availabilityList: {
              filterType: 'number',
              type: 'greaterThan',
              filter: 0,
            },
            preorder: {
              filterType: 'boolean',
              type: 'equals',
              filter: 0,
            },
          },
        };
      } else if (
        availabilityListArray.length === 1 &&
        availabilityListArray.includes(aggridFilterConstants.FILTER_UNAVAILABLE)
      ) {
        // # INDISPO COCHE UNIQUEMENT
        filterModel = {
          ...filterModel,
          ...{
            availabilityList: {
              filterType: 'number',
              type: 'lessThan',
              filter: 0,
            },
            preorder: {
              filterType: 'boolean',
              type: 'equals',
              filter: 0,
            },
          },
        };
      } else if (
        availabilityListArray.length === 1 &&
        availabilityListArray.includes(aggridFilterConstants.FILTER_PREORDER)
      ) {
        // # PREORDER COCHE UNIQUEMENT
        filterModel = {
          ...filterModel,
          ...{
            preorder: {
              filterType: 'boolean',
              type: 'equals',
              filter: 1,
            },
          },
        };
      } else if (
        availabilityListArray.includes(aggridFilterConstants.FILTER_AVAILABLE) &&
        !availabilityListArray.includes(aggridFilterConstants.FILTER_UNAVAILABLE) &&
        availabilityListArray.includes(aggridFilterConstants.FILTER_PREORDER)
      ) {
        // # STOCK COCHE + PREORDER COCHE
        filterModel = {
          ...filterModel,
          ...{
            availability_preorder: {
              filterType: 'number',
              type: 'greaterThan',
              filter: 0,
            },
          },
        };
      } else if (
        availabilityListArray.includes(aggridFilterConstants.FILTER_UNAVAILABLE) &&
        !availabilityListArray.includes(aggridFilterConstants.FILTER_AVAILABLE) &&
        availabilityListArray.includes(aggridFilterConstants.FILTER_PREORDER)
      ) {
        // # INDISPO COCHE + PREORDER COCHE
        filterModel = {
          ...filterModel,
          ...{
            availability_preorder: {
              filterType: 'number',
              type: 'lessThan',
              filter: 0,
            },
          },
        };
      } else if (
        availabilityListArray.includes(aggridFilterConstants.FILTER_UNAVAILABLE) &&
        availabilityListArray.includes(aggridFilterConstants.FILTER_AVAILABLE) &&
        !availabilityListArray.includes(aggridFilterConstants.FILTER_PREORDER)
      ) {
        // # STOCK COCHE + INDISPO COCHE
        filterModel = {
          ...filterModel,
          ...{
            preorder: {
              filterType: 'boolean',
              type: 'equals',
              filter: 0,
            },
          },
        };
      }
    }
    if (
      aggridFilter &&
      aggridFilter.storeAvailabilityList &&
      aggridFilter.storeAvailabilityList.length > 0
    ) {
      const storeAvailabilityListArray = aggridFilter.storeAvailabilityList;
      if (
        storeAvailabilityListArray.length === 1 &&
        storeAvailabilityListArray.includes(aggridFilterConstants.FILTER_STORE_AVAILABLE)
      ) {
        // # STORE_AVAILABLE COCHE (UNIQUEMENT)
        filterModel = {
          ...filterModel,
          ...{
            storeAvailabilityList: {
              filterType: 'number',
              type: 'greaterThan',
              filter: 0,
            },
          },
        };
      } else if (
        storeAvailabilityListArray.length === 1 &&
        storeAvailabilityListArray.includes(aggridFilterConstants.FILTER_STORE_UNAVAILABLE)
      ) {
        // # STORE_UNAVAILABLE COCHE (UNIQUEMENT)
        filterModel = {
          ...filterModel,
          ...{
            storeAvailabilityList: {
              filterType: 'number',
              type: 'lessThan',
              filter: 0,
            },
          },
        };
      } else if (
        storeAvailabilityListArray.length === 1 &&
        storeAvailabilityListArray.includes(aggridFilterConstants.FILTER_STORE_NO_ASSORTMENT)
      ) {
        // # STORE_NO_ASSORTMENT COCHE (UNIQUEMENT)
        filterModel = {
          ...filterModel,
          ...{
            storeAvailabilityList: {
              filterType: 'null',
              type: 'equals',
              filter: null,
            },
          },
        };
      } else if (
        storeAvailabilityListArray.length === 2 &&
        storeAvailabilityListArray.includes(aggridFilterConstants.FILTER_STORE_AVAILABLE) &&
        storeAvailabilityListArray.includes(aggridFilterConstants.FILTER_STORE_UNAVAILABLE)
      ) {
        // # STORE INDISPO COCHE + STORE STOCK COCHE (UNIQUEMENT)
        filterModel = {
          ...filterModel,
          ...{
            storeAvailabilityList: {
              filterType: 'number',
              type: 'greaterThan',
              filter: '-999999',
            },
          },
        };
      } else if (
        storeAvailabilityListArray.length === 2 &&
        storeAvailabilityListArray.includes(aggridFilterConstants.FILTER_STORE_UNAVAILABLE) &&
        storeAvailabilityListArray.includes(aggridFilterConstants.FILTER_STORE_NO_ASSORTMENT)
      ) {
        // # STORE INDISPO COCHE + STORE HORS ASSORTIMENT COCHE (UNIQUEMENT)
        filterModel = {
          ...filterModel,
          ...{
            storeAvailabilityList: {
              filterType: 'null',
              type: 'equals',
              filter: -1,
            },
          },
        };
      } else if (
        storeAvailabilityListArray.length === 2 &&
        storeAvailabilityListArray.includes(aggridFilterConstants.FILTER_STORE_AVAILABLE) &&
        storeAvailabilityListArray.includes(aggridFilterConstants.FILTER_STORE_NO_ASSORTMENT)
      ) {
        // # STORE STOCK COCHE + STORE HORS ASSORTIMENT COCHE (UNIQUEMENT)
        filterModel = {
          ...filterModel,
          ...{
            storeAvailabilityList: {
              filterType: 'null',
              type: 'equals',
              filter: 1,
            },
          },
        };
      }
    }
    if (aggridFilter && aggridFilter.temperatureList && aggridFilter.temperatureList.length > 0) {
      filterModel = {
        ...filterModel,
        ...{
          temperatureList: {
            filterType: 'array',
            type: 'equals',
            filter: aggridFilter.temperatureList,
          },
        },
      };
    }
    return filterModel;
  };

  getDataSource = (refGridApi) => {
    return {
      getRows: (params) => {
        const state = store.getState();
        const { platform, aggridFilter, aggridSearchBar } = state;
        const {
          startRow,
          endRow,
          filterModel: filterModelSource,
          sortModel: sortModelSource,
        } = params;
        // define default filterModel
        const filterModel = this.getFilterModel(aggridFilter, filterModelSource);
        // define default sortModel
        let sortModel = sortModelSource;
        if (sortModel.length === 0) {
          sortModel = [
            { sort: 'asc', colId: 'category_name' },
            { sort: 'asc', colId: 'name' },
          ];
        }
        refGridApi.showLoadingOverlay();
        const groupId = aggridFilter.selection || null;
        const parameters = {
          platform: platform.selectedId,
          clientId: null,
          groupItem: groupId,
        };

        let keywords;
        if (
          aggridSearchBar &&
          aggridSearchBar.searchValue &&
          aggridSearchBar.searchValue.length > 2
        ) {
          keywords = aggridSearchBar.searchValue;
        }
        const defaultLimit = 100;
        if (aggridFilterConstants.FILTER_FAVORITE in filterModel) {
          catalogService
            .getCatalogFavoritesV4(parameters, startRow, defaultLimit, {}, sortModel, null)
            .then((response) => {
              this.setState({ searchValue: null });
              let lastRow = -1;
              if (response.rowCount <= params.endRow) {
                lastRow = response.rowCount;
              }

              let rowCount;
              const totalRowCount = response.rowCount;
              rowCount = params.endRow;

              const dataProducts = response.rowData;

              if (params.endRow >= totalRowCount) {
                rowCount = totalRowCount;
              }

              if (totalRowCount === 0) refGridApi.showNoRowsOverlay();
              if (totalRowCount > 0) refGridApi.hideOverlay();
              this.setState({ rowCount });
              this.setState({ totalRowCount });
              if (response.counters) {
                this.setState({ counters: response.counters });
                const { setCountersFilters } = this.props;
                setCountersFilters(response.counters.favorites);
              }
              params.successCallback(dataProducts, lastRow);
            })
            .catch((error) => {
              params.failCallback();
            });
        } else if (aggridFilterConstants.FILTER_PROMOTION in filterModel) {
          catalogService
            .getCatalogPromotionsV4(
              parameters,
              startRow,
              defaultLimit,
              filterModel,
              sortModel,
              keywords
            )
            .then((response) => {
              let lastRow = -1;
              if (response.rowCount <= params.endRow) {
                lastRow = response.rowCount;
              }

              let rowCount;
              const totalRowCount = response.rowCount;
              rowCount = params.endRow;

              const dataProducts = response.rowData;

              if (params.endRow >= totalRowCount) {
                rowCount = totalRowCount;
              }

              if (totalRowCount === 0) refGridApi.showNoRowsOverlay();
              if (totalRowCount > 0) refGridApi.hideOverlay();
              this.setState({ rowCount });
              this.setState({ totalRowCount });
              if (response.counters) {
                this.setState({ counters: response.counters });
                const { setCountersFilters } = this.props;
                setCountersFilters(response.counters.favorites);
              }
              params.successCallback(dataProducts, lastRow);
            })
            .catch((error) => {
              params.failCallback();
            });
        } else if (aggridFilterConstants.FILTER_TOP_SALES in filterModel) {
          catalogService
            .getTopSalesV4(parameters, startRow, defaultLimit, filterModel, sortModel, keywords)
            .then((response) => {
              let lastRow = -1;
              if (response.rowCount <= params.endRow) {
                lastRow = response.rowCount;
              }

              let rowCount;
              const totalRowCount = response.rowCount;
              rowCount = params.endRow;

              const dataProducts = response.rowData;

              if (params.endRow >= totalRowCount) {
                rowCount = totalRowCount;
              }

              if (totalRowCount === 0) refGridApi.showNoRowsOverlay();
              if (totalRowCount > 0) refGridApi.hideOverlay();
              this.setState({ rowCount });
              this.setState({ totalRowCount });
              if (response.counters) {
                this.setState({ counters: response.counters });
                const { setCountersFilters } = this.props;
                setCountersFilters(response.counters.favorites);
              }
              params.successCallback(dataProducts, lastRow);
            })
            .catch((error) => {
              params.failCallback();
            });
        } else {
          catalogService
            .fetchOptimizedItemsV4(
              parameters,
              startRow,
              defaultLimit,
              filterModel,
              sortModel,
              keywords
            )
            .then((response) => {
              let lastRow = -1;
              if (response.rowCount <= params.endRow) {
                lastRow = response.rowCount;
              }

              let rowCount;
              const totalRowCount = response.rowCount;
              rowCount = params.endRow;

              // If no results
              if (totalRowCount === 0) {
                const state = store.getState();
                const currentPlatform = selectCurrentPlatform(state);

                catalogService.fetchTopSellingItems(currentPlatform.id).then((response) => {
                  const dataProducts = response.topSellingItems;
                  const productsNumber = dataProducts.length;

                  if (dataProducts.length > 0) {
                    this.setState({ hasResults: false });
                    this.setState({ rowCount: productsNumber });
                    this.setState({ totalRowCount: productsNumber });
                    refGridApi.hideOverlay();
                    params.successCallback(dataProducts, productsNumber);
                  } else {
                    refGridApi.showNoRowsOverlay();
                  }
                });
              } else {
                const dataProducts = response.rowData;

                if (params.endRow >= totalRowCount) {
                  rowCount = totalRowCount;
                }
                
                if (totalRowCount > 0) refGridApi.hideOverlay();
                this.setState({ hasResults: true });
                this.setState({ rowCount });
                this.setState({ totalRowCount });

                if (response.counters) {
                  this.setState({ counters: response.counters });
                  const { setCountersFilters } = this.props;
                  setCountersFilters(response.counters.favorites);
                }
                params.successCallback(dataProducts, lastRow);
              }
            })
            .catch((error) => {
              params.failCallback();
            });
        }
      },
    };
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    const state = store.getState();
    const currentPlatform = selectCurrentPlatform(state);
    const { attributs } = currentPlatform;
    const shouldDisplayStock = !Number(
      getValueOfAttribut(attributs || [], platformConstants.STOCK_DISPLAY_OUT_KEY)
    );

    if (!shouldDisplayStock) {
      this.gridColumnApi.setColumnVisible('availability', false);
    }

    this.gridApi.sizeColumnsToFit();
    window.onresize = () => {
      this.gridApi.sizeColumnsToFit();
    };

    const datasource = this.getDataSource(this.gridApi);
    this.gridApi.setDatasource(datasource);
  };

  onSelectionChanged = (event) => {
    const { selectedPlatform } = this.props;

    if (aggridConstants.FIXED_COLUMNS.includes(event.column.colId)) {
      return;
    }

    // Degressive prices popin button
    if (
      event.event.target !== undefined &&
      event.event.target.classList.contains('ag-tooltip__link')
    ) {
      return;
    }

    event.node.setSelected(true);
    const { chooseCurrentGridProduct } = this.props;
    const currentProduct = this.gridApi.getSelectedRows();
    chooseCurrentGridProduct(currentProduct[0], selectedPlatform);
  };

  onFirstDataRendered = (params) => {
    params.api.sizeColumnsToFit();
  };

  externalFilterChanged = (newValue) => {
    if (this.gridApi) {
      this.setState({ filterType: newValue }, () => {
        this.gridApi.onFilterChanged();
      });
    }
  };

  isExternalFilterPresent = () => this.state.filterType !== aggridConstants.FILTER_NO_FILTER;

  doesExternalFilterPass = (node) => {
    const { filterType } = this.state;
    const { topSales } = this.props;

    if (!filterType) {
      return true;
    }

    return doFiltersPass(
      filterType.quickList,
      filterType.selection,
      filterType.category,
      filterType.availabilityList,
      filterType.storeAvailabilityList,
      filterType.temperatureList,
      node.data,
      topSales.items
    );
  };

  navigateToNextCell = (params) => {
    const { chooseCurrentGridProduct, selectedPlatform } = this.props;

    let previousCell = params.previousCellPosition;
    const suggestedNextCell = params.nextCellPosition;

    const KEY_UP = 38;
    const KEY_DOWN = 40;
    const KEY_LEFT = 37;
    const KEY_RIGHT = 39;

    switch (params.key) {
      case KEY_DOWN:
        previousCell = params.previousCellPosition;
        // set selected cell on current cell + 1
        this.gridApi.forEachNode((node) => {
          if (previousCell.rowIndex + 1 === node.rowIndex) {
            node.setSelected(true);
            chooseCurrentGridProduct(node.data, selectedPlatform);
          }
        });
        return suggestedNextCell;
      case KEY_UP:
        previousCell = params.previousCellPosition;
        // set selected cell on current cell - 1
        this.gridApi.forEachNode((node) => {
          if (previousCell.rowIndex - 1 === node.rowIndex) {
            node.setSelected(true);
            chooseCurrentGridProduct(node.data, selectedPlatform);
          }
        });
        return suggestedNextCell;
      case KEY_LEFT:
        return suggestedNextCell;
      case KEY_RIGHT:
        return suggestedNextCell;
      default:
        return null;
    }
  };

  render() {
    const { rowCount } = this.state;
    const { counters } = this.state;
    const { searchValue } = this.state;
    const { totalRowCount } = this.state;
    const state = store.getState();
    const { favorites } = state.product;
    const counters2 = favorites.itemsCount;
    const { hasResults } = this.state;
    const {
      items,
      topSales,
      selectedPlatform,
      currentProductCard,
      close,
      viewType,
      pageContext,
      setCurrentFilters,
      currentFilters,
    } = this.props;

    let itemsWithTopSales = null;
    if (items.length && topSales.items && topSales.items.length) {
      itemsWithTopSales = items.map((item) => ({
        ...item,
        topPosition: topSales.items.includes(item.id) ? topSales.items.indexOf(item.id) + 1 : null,
      }));
    }

    const frameworkComponents = {
      availabilityCellRenderer,
      priceCellRenderer,
      packagePriceCellRenderer,
      imageCellRenderer,
      AssortmentFilter,
      Spinner,
    };

    const aggridProps = {
      rowData: itemsWithTopSales || items,
      onGridReady: this.onGridReady,
      onFirstDataRendered: this.onFirstDataRendered,
      onCellClicked: this.onSelectionChanged,
      onFilterChanged: this.onFilterChanged,
      frameworkComponents,
      doesExternalFilterPass: this.doesExternalFilterPass,
      isExternalFilterPresent: this.isExternalFilterPresent,
      handleFilter: this.externalFilterChanged,
      currentProduct: currentProductCard,
      close,
      columnApi: this.gridColumnApi,
      gridApi: this.gridApi,
      rowCount,
      totalRowCount,
      navigateToNextCell: this.navigateToNextCell,
      searchValue: this.props.searchValue,
      viewType,
      pageContext,
      counters,
      counters2,
      hasResults,
    };

    return (
      <>
        {viewType && currentProductCard && (
          <div className="ProductCardContainer">
            <ProductCard
              product={currentProductCard}
              close={close}
              handleFilter={this.externalFilterChanged}
            />
          </div>
        )}
        {items && (
          <CatalogControls
            selectedPlatform={selectedPlatform}
            handleFilter={this.externalFilterChanged}
            rowData={items}
            viewType={viewType}
            counters={counters}
            counters2={counters2}
            searchValue={searchValue}
          />
        )}

        {viewType && <AggridTableView {...aggridProps} source="AggridTableView" />}
        {!viewType && <AggridListView {...aggridProps} />}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const platformId = selectCurrentPlatformId(state);

  return {
    platformId,
    selectedPlatform: selectCurrentPlatform(state),
    items: selectItemsFromCatalog(state, { aggrid: true }),
    isItemsLoading: selectIsLoadingFromCatalog(state, { aggrid: true }),
    categories: selectCategories(state),
    searchValue: selectAggridSearchValue(state),
    currentProduct: selectCurrentProduct(state),
    currentFilters: selectAggridFilter(state),
    counters: selectNewsPromotionsFavoritesCountersProducts(state),
    viewType: selectAggridViewType(state),
    messageList: selectMessageList(state, { platform: selectCurrentPlatformId(state) }),
    topSales: selectTopSales(state),
    user: selectCurrentUser(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  setCountersFilters: (counters) => dispatch(productActions.setCountersFilters(counters)),
  fetchProducts: (platformId, categoryIds = []) =>
    dispatch(productActions.fetchAllProducts(platformId, categoryIds, 50000)),
  chooseCurrentGridProduct: (product, platform) => {
    analytics.productDetails(product);
    dispatch(productCardActions.chooseCurrentProduct(product, platform));
  },
  pushToast: (toast) => dispatch(toastActions.addToast(toast)),
  setToastList: (toastList) => dispatch(toastActions.setToastList(toastList)),
  fetchTopSalesItemsIdList: (platformId, startDate, endDate, limit) =>
    dispatch(catalogActions.fetchTopSalesItemIdList(platformId, startDate, endDate, limit)),
  setCurrentFilters: (filters) => dispatch(aggridFilterActions.setCurrentFilters(filters)),
});

Aggrid.propTypes = {
  platformId: PropTypes.number,
  selectedPlatform: PropTypes.object,
  fetchProducts: PropTypes.func,
  chooseCurrentGridProduct: PropTypes.func,
  items: PropTypes.array,
  searchValue: PropTypes.string,
  categoryDisplay: PropTypes.bool,
  setCurrentFilters: PropTypes.func,
  currentFilters: PropTypes.shape({
    quickList: PropTypes.arrayOf(PropTypes.string),
    temperatureList: PropTypes.arrayOf(PropTypes.string),
    availabilityList: PropTypes.arrayOf(PropTypes.string),
    category: PropTypes.string,
    selectedCategoryList: PropTypes.array,
    categoryColumns: PropTypes.array,
  }),
};

export default connect(mapStateToProps, mapDispatchToProps)(Aggrid);
